import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Lockestake from "../Lockestak/Lockestake";
import "./Tab_style.css";
import Mylock from "../myLock/Mylock";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({  totalUserAmount }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }} className="text-light ">
            <Box sx={{}}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="tab-css"
                centered
              >
                <Tab
                  className="text-light mak_flxx_bttn"
                  label="Stake"
                  {...a11yProps(0)}
                />
                <Tab
                  className="text-light mak_flxx_bttn"
                  label="Staked History"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={1}>
              <Mylock />
            </TabPanel>
            <TabPanel value={value} index={0}>
              <div class="staking">
                <h2 class="staking__heading">TOTAL Groooook LOCKED</h2>
                <div class="staking__tvl">
                  <span class="staking__tvl_text">
                    Total Groooook in Locked Staking
                  </span>
                  <span
                    class="staking__tvl_text"
                    style={{ color: "rgb(255, 255, 255)", fontWeight: "700" }}
                  >
                    {totalUserAmount} Groooook
                  </span>
                </div>
              </div>

              <Lockestake  />
            </TabPanel>
          </Box>
    </>
  );
}
